<div class="autocomplete form-floating has-validation">
    <ng-container *ngIf="!useTextarea; else textareaTemplate">
        <input [formControl]="searchControl"
               [ngClass]="{
                'is-invalid': control.invalid && control.touched
               }"
               type="text"
               class="form-control"
               [placeholder]="placeholder"
               autocomplete="off"
               [readOnly]="isReadonly || isDropdown"
               [typeahead]="typeaheadAsync ? asyncTypeaheadList : typeaheadList"
               [typeaheadAsync]="typeaheadAsync"
               [typeaheadOptionField]="typeaheadOptionField"
               [typeaheadScrollable]="typeaheadScrollable"
               [typeaheadOptionsInScrollableView]="typeaheadOptionsInScrollableView"
               [typeaheadOptionsLimit]="typeaheadOptionsLimit"
               [typeaheadMinLength]="typeaheadMinLength"
               [typeaheadSelectFirstItem]="true"
               [typeaheadIsFirstItemActive]="true"
               [optionsListTemplate]="isDropdown ? dropdownListTemplate : undefined"
               (blur)="blurEvent($event)"
               (focus)="focusEvent()"
               (typeaheadOnSelect)="selectItem($event)">
    </ng-container>
    <ng-template #textareaTemplate>
        <textarea [formControl]="searchControl"
                  [ngClass]="{
                   'is-invalid': control.invalid && control.touched
                  }"
                  class="form-control"
                  [placeholder]="placeholder"
                  autocomplete="off"
                  [readOnly]="isReadonly || isDropdown"
                  [typeahead]="typeaheadAsync ? asyncTypeaheadList : typeaheadList"
                  [typeaheadAsync]="typeaheadAsync"
                  [typeaheadOptionField]="typeaheadOptionField"
                  [typeaheadScrollable]="typeaheadScrollable"
                  [typeaheadOptionsInScrollableView]="typeaheadOptionsInScrollableView"
                  [typeaheadOptionsLimit]="typeaheadOptionsLimit"
                  [typeaheadMinLength]="typeaheadMinLength"
                  [typeaheadSelectFirstItem]="true"
                  [typeaheadIsFirstItemActive]="true"
                  [optionsListTemplate]="isDropdown ? dropdownListTemplate : undefined"
                  (typeaheadOnSelect)="selectItem($event)"
                  (input)="adjustHeight($event)"
                  (blur)="blurEvent($event)"
                  (focus)="focusEvent()"></textarea>
    </ng-template>
    <svg *ngIf="!this.suggestionPart" class="icon-chevron" width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 0.5L7 5.99994L12.5 0.5L14 2L7 8.99994L0 2L1.5 0.5Z" fill="#0B1F35"/>
    </svg>
    <label>{{label}}</label>

    <div *ngIf="isLoading" class="spinner-block spinner-grow spinner-grow-sm text-secondary" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<ng-template #dropdownListTemplate class="custom-list-group">
    <ul class="custom-list-group" [style.height]="typeaheadList.length > 7 ? 7 * 46 + 'px' : 'auto'">
        <li
            *ngFor="let match of typeaheadList; let i = index;"
            (click)="selectItem(match)" href="#">
            <a class="dropdown-item" [class.active]="match.value === searchControl.value" >
                {{ match.value }}
            </a>
        </li>
    </ul>
</ng-template>
<app-form-field-error
    *ngIf="control?.touched"
    [controlErrors]="control.errors"
    [messages]="messages">
</app-form-field-error>
