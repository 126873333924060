<div class="owner">
    <app-breadcrumb></app-breadcrumb>

    <h2 class="mb-4">Собственник</h2>

    <div class="row mb-4 driver-selector"
         *ngIf="formService.form.get('drivers')?.value?.length >= 1 && getDriverTitle(formService.form.get('drivers')?.value[0])">
        <div class="col-auto" *ngFor="let driver of formService.form.get('drivers')?.value">
            <button *ngIf="getDriverTitle(driver) !== ''" (click)="selectDriver(driver)"
                    [class.active]="checkActiveDriver(driver, true)"
                    type="button" class="btn btn-outline-dark mb-2">
                {{ getDriverTitle(driver) }}
            </button>
        </div>
        <div class="col-auto">
            <button (click)="resetInfoDriver()"
                    [class.active]="checkActiveDriver(null, true)"
                    type="button" class="btn btn-outline-dark">
                Другой
            </button>
        </div>
    </div>

    <form [formGroup]="formOwner" novalidate autocomplete="off">
        <div class="row mb-4">
            <div class="col-12 col-md-4 col-xxl-4 mb-4">
                <app-autocomplete
                    formControlName="ownerLastName"
                    [suggestionPart]="daDataSystemName.SURNAME"
                    [isReadonly]="isLoading"></app-autocomplete>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-4">
                <app-autocomplete
                    formControlName="ownerFirstName"
                    [suggestionPart]="daDataSystemName.NAME"
                    [isReadonly]="isLoading"></app-autocomplete>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-4">
                <app-autocomplete
                    formControlName="ownerMiddleName"
                    [suggestionPart]="daDataSystemName.PATRONYMIC"
                    [isReadonly]="isLoading"></app-autocomplete>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-4">
                <app-form-date-field formControlName="ownerBirthDate" [isReadonly]="isLoading"></app-form-date-field>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-4" *ngIf="formOwner.get('passportLicense')?.enabled">
                <app-form-text-field formControlName="passportLicense" [isReadonly]="isLoading"></app-form-text-field>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-4" *ngIf="formOwner.get('ownerPassportDate')?.enabled">
                <app-form-date-field formControlName="ownerPassportDate" [isReadonly]="isLoading"></app-form-date-field>
            </div>
            <div class="col-12 col-md-12 mb-4">
                <app-autocomplete
                    formControlName="ownerCity"
                    [useTextarea]="true"
                    [daDataControlName]="'ownerAddressDaData'"
                    [suggestionPart]="daDataSystemName.ADDRESS"
                    [isReadonly]="isLoading"></app-autocomplete>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-3">
                <div class="form-check form-switch form-switch-juridical form-check-reverse">
                    <input class="form-check-input" type="checkbox" id="ownerIsJuridical" formControlName="ownerIsJuridical">
                    <label class="form-check-label" for="ownerIsJuridical">Юридическое лицо</label>
                </div>
            </div>
        </div>
    </form>

    <h2 class="mb-2">Страхователь</h2>

    <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="ownerIsInsured" [formControl]="ownerIsInsured">
        <label class="form-check-label" for="ownerIsInsured">Cтрахователь является собственником авто {{ getOwnerNameTitle() }}</label>
    </div>

    <form [formGroup]="formInsurer" novalidate [hidden]="ownerIsInsured.value">

        <div class="row mt-2 mb-3 driver-selector"
             *ngIf="formService.form.get('drivers')?.value?.length >= 1 && getDriverTitle(formService.form.get('drivers')?.value[0])">
            <div class="col-auto" *ngFor="let driver of formService.form.get('drivers')?.value">
                <button *ngIf="getDriverTitle(driver) !== ''" (click)="selectDriver(driver, false)"
                        [class.active]="checkActiveDriver(driver, true)"
                        type="button" class="btn btn-outline-dark mb-2">
                    {{ getDriverTitle(driver) }}
                </button>
            </div>
            <div class="col-auto">
                <button (click)="resetInfoDriver(false)"
                        [class.active]="checkActiveDriver(null, true)"
                        type="button" class="btn btn-outline-dark">
                    Другой
                </button>
            </div>
        </div>

        <div class="alert alert-secondary alert-dismissible border-0 mb-3" role="alert">
            <div>Страхователем может быть только собственник или один из водителей</div>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>

        <div class="row mb-4">
            <div class="col-12 col-md-4 col-xxl-4 mb-4">
                <app-autocomplete
                    formControlName="insurerLastName"
                    [suggestionPart]="daDataSystemName.SURNAME"
                    [isReadonly]="isLoading"></app-autocomplete>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-4">
                <app-autocomplete
                    formControlName="insurerFirstName"
                    [suggestionPart]="daDataSystemName.NAME"
                    [isReadonly]="isLoading"></app-autocomplete>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-4">
                <app-autocomplete
                    formControlName="insurerMiddleName"
                    [suggestionPart]="daDataSystemName.PATRONYMIC"
                    [isReadonly]="isLoading"></app-autocomplete>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-4">
                <app-form-date-field formControlName="insurerBirthDate" [isReadonly]="isLoading"></app-form-date-field>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-4" *ngIf="formInsurer.get('passportLicense')?.enabled">
                <app-form-text-field formControlName="passportLicense" [isReadonly]="isLoading"></app-form-text-field>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-4" *ngIf="formInsurer.get('insurerPassportDate')?.enabled">
                <app-form-date-field formControlName="insurerPassportDate" [isReadonly]="isLoading"></app-form-date-field>
            </div>
            <div class="col-12 col-md-12 mb-4">
                <app-autocomplete
                    formControlName="insurerCity"
                    [useTextarea]="true"
                    [daDataControlName]="'insurerAddressDaData'"
                    [suggestionPart]="daDataSystemName.ADDRESS"
                    [isReadonly]="isLoading"></app-autocomplete>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-3">
                <div class="form-check form-switch form-switch-juridical form-check-reverse">
                    <input class="form-check-input" type="checkbox" id="insurerIsJuridical" formControlName="insurerIsJuridical">
                    <label class="form-check-label" for="insurerIsJuridical">Юридическое лицо</label>
                </div>
            </div>
        </div>
    </form>

    <form [formGroup]="formContacts" novalidate>

        <div class="alert alert-secondary alert-dismissible border-0 my-3" role="alert">
            <div>Телефон нужен страховой компании для подтверждения расчёта КАСКО, а на указанную почту мы вышлем готовый полис</div>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>

        <div class="row">
            <div class="col-12 col-md-4 col-xxl-4 mb-4">
                <app-form-text-field formControlName="email" [isReadonly]="isLoading"></app-form-text-field>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-4">
                <app-form-text-field formControlName="phone" [isReadonly]="isLoading"></app-form-text-field>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-4"
                 [hidden]="formContacts.get('smsCode')?.disabled">
                <app-form-number-field formControlName="smsCode" (input)="inputSmsCode($event)" [isReadonly]="isLoading"></app-form-number-field>
            </div>
        </div>
    </form>
    <div class="row align-items-center actions">
        <div class="col-12 col-sm-6 col-md-4 order-1 order-sm-0 mb-3">
            <button type="button" routerLink="/drivers" queryParamsHandling="merge" class="btn btn-outline-primary btn-lg w-100">Назад</button>
        </div>
        <div class="col-12 col-sm-6 col-md-4 order-2 order-sm-1 mb-3">
            <button type="button" (click)="sendSmsCode()" class="btn btn-primary btn-lg w-100" [disabled]="isLoading || isShowTimer">
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                <span *ngIf="!isLoading">Продолжить</span>
            </button>
<!--            <button *ngIf="kaskoService.checkStatusSendSms()" type="button" (click)="sendSmsCode()" class="btn btn-primary btn-lg w-100" [disabled]="isLoading">-->
<!--                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"-->
<!--                      aria-hidden="true"></span>-->
<!--                <span *ngIf="!isLoading">Продолжить</span>-->
<!--            </button>-->
        </div>
        <div class="col-12 col-sm-6 col-md-4 order-0 order-sm-2 mb-3" *ngIf="isShowTimer && formContacts.get('smsCode')?.enabled">
            <app-timer [min]="0" [sec]="30" [timerText]="'Запросить повторно через'" (changeTimerValue)="changeTimerValue($event)"></app-timer>
        </div>
        <div class="col-12 col-sm-6 col-md-4 order-0 order-sm-2 mb-3" *ngIf="!isShowTimer && formContacts.get('smsCode')?.enabled">
            <button (click)="sendSmsCode(true)" [disabled]="isLoading || isShowTimer" type="button" class="btn btn-outline-primary btn-lg w-100">Отправить смс код еще раз</button>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div class="form-check">
                <input [formControl]="policy" class="form-check-input"
                       [ngClass]="{
                                    'is-invalid': policy.invalid && policy.touched
                                   }"
                       type="checkbox" id="policy">
                <label class="form-check-label" for="policy">
                    <p class="mb-0">Согласен с <a href="./assets/files/privacy.pdf" target="_blank">правилами предоставления информации</a>
                    </p>
                </label>
            </div>
            <app-form-field-error
                *ngIf="policy.invalid && policy.touched"
                [controlErrors]="policy.errors"
                [messages]="policyValidationMessage">
            </app-form-field-error>
        </div>
    </div>

</div>
